import styled from 'styled-components';

import { font } from '../../../styles/atoms/typography';
import { colors } from '../../../styles/atoms/colors';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Text = styled.div`
  width: 100%;
  max-width: 770px;
  height: auto;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  margin-bottom: 64px;

  .heading {
    ${font('display', 'lg', '700')}
    color: ${colors.gray[900]};
    ${atMinWidth.lg`
        ${font('display', 'xxl', '700')}
    `}
  }

  .subhead {
    ${font('text', 'md', '400')}
    color: ${colors.gray[600]};
    ${atMinWidth.sm`
        ${font('text', 'lg', '400')}
    `}
    ${atMinWidth.lg`
        ${font('text', 'xl', '400')}
    `}
  }

  .buttons {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    ${atMinWidth.xs`
        flex-direction: row;
    `}
  }

  .btn-outline-gray {
    background: ${colors.base.white};
    border: 1px solid ${colors.gray[300]};
    border-radius: 8px;
  }
`;
