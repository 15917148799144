import { navigate } from 'gatsby';
import React, { useCallback } from 'react';
import Badge from '../../../molecules/Badge/Badge';
import BadgeContainer from '../../../molecules/Badge/Container/BadgeContainer';
import { colors } from '../../../styles/atoms/colors';
import Img from '../../../utils/OptimizedImage';
import getReadTimeLabel from '../../../utils/getReadTimeLabel';
import {
  CaseStudyCardStyles,
  ContentContainer,
  Heading,
  ImageContainer,
  MetaLabel,
} from './CaseStudyCard.styles';

const CaseStudyCard = ({
  excerpt,
  image,
  internalName,
  slug,
  tags,
  wordCount,
}) => {
  const handleClickCard = useCallback(() => {
    navigate(`/case-studies/${slug}`);
  }, [slug]);

  return (
    <CaseStudyCardStyles onClick={handleClickCard}>
      <ImageContainer>
        {image?.gatsbyImageData ? (
          <Img
            image={image?.gatsbyImageData}
            alt={image?.alt}
            title={image?.title}
          />
        ) : (
          <Img
            src={image.url}
            alt={image?.alt}
            title={image?.title}
          />
        )}
      </ImageContainer>
      <ContentContainer>
        <div className="body">
          <Heading>{internalName}</Heading>
          {excerpt && <div className="excerpt">{excerpt}</div>}
        </div>
        <div className="footer">
          <BadgeContainer>
            {tags &&
              tags.length > 0 &&
              tags.map(tag => (
                <Badge
                  badgeColor={colors.primary[50]}
                  key={tag.id}
                  size="sm"
                  textColor={colors.primary[500]}
                >
                  {tag.name}
                </Badge>
              ))}
          </BadgeContainer>
          {wordCount > 0 && (
            <MetaLabel>{getReadTimeLabel(wordCount)}</MetaLabel>
          )}
        </div>
      </ContentContainer>
    </CaseStudyCardStyles>
  );
};

export default CaseStudyCard;
