import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';

import CaseStudyCard from '../../CaseStudyList/CaseStudyCard/CaseStudyCard';

import { Text } from '../styles/CaseStudyListingHero.styled';
import { customInnerContentStyles } from '../styles/HeroNewStyles';

const CaseStudyListingHero = () => {
  const {
    datoCmsFeaturedCaseStudy: { caseStudy },
  } = useStaticQuery(CaseStudyListingQuery);

  return (
    <Section
      id={caseStudy.id}
      innerStyle={customInnerContentStyles}
      backgroundImageName="light-hex"
    >
      <Text>
        <h1 className="heading">Welcome to Case Studies</h1>
        <p className="subhead">
          Find out how shops around the country have improved the way they do
          business with Shopmonkey.
        </p>
        <div className="buttons">
          <CallToAction
            value="Schedule a Demo"
            link="/demo"
            variant="primary"
          />
          <CallToAction
            value="Read Featured Article"
            link={`/case-studies/${caseStudy?.slug}`}
            variant="tertiary"
          />
        </div>
      </Text>
      <CaseStudyCard
        image={caseStudy.thumbnail}
        internalName={caseStudy.internalName}
        excerpt={caseStudy.excerpt}
        slug={caseStudy.slug}
        tags={caseStudy.tag}
      />
    </Section>
  );
};

export default CaseStudyListingHero;

const CaseStudyListingQuery = graphql`
  {
    datoCmsFeaturedCaseStudy {
      ...datoCmsFeaturedCaseStudy
    }
  }
`;
